// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import Cluster from "views/Dashboard/Cluster.js";
import Workshop from "views/Dashboard/Workshop.js";
import Generator from "views/Dashboard/Generator.js"
import Marketplace from "views/Dashboard/Marketplace";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import SignOut from "views/Pages/SignOut.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  LogoutIcon
} from "components/Icons/Icons";
import { CartIcon } from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Data center",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/generator",
    name: "Generator",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Generator,
    layout: "/admin",
  },
  {
    path: "/workshop",
    name: "Workshop",
    rtlName: "آرتيإل",
    icon: <SupportIcon color="inherit" />,
    component: Workshop,
    layout: "/admin",
  },
  {
    path: "/cluster",
    name: "Cluster",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color="inherit" />,
    component: Cluster,
    layout: "/admin",
  },
  {
    name: "MARKET",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "https://nftkey.app",
        name: "NFT Key",
        rtlName: "لوحة القيادة",
        icon: <CartIcon color="inherit" />,
        secondaryNavbar: true,
        component: Marketplace,
        layout: "",
      },
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
        hide: true
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
        hide: true
      },
      {
        path: "/signout",
        name: "Exit",
        rtlName: "لوحة القيادة",
        //icon: "",
        component: SignOut,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
