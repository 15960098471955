// Chakra imports
import {
  Box,
  Button,
  fadeConfig,
  Flex,
  Grid,
  SimpleGrid,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import mb from "assets/img/motherboard.jpg";
import plate from "assets/img/plate.jpg";
import working from "assets/img/working.jpg";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import { MastercardIcon, VisaIcon, HelpIcon } from "components/Icons/Icons";
import InvoicesRow from "components/Tables/InvoicesRow";
import TransactionRow from "components/Tables/TransactionRow";
import { Separator } from "components/Separator/Separator";
import React from "react";
import {
  FaAdjust,
  FaPaypal,
  FaPencilAlt,
  FaPowerOff,
  FaRegCalendarAlt,
  FaWallet,
} from "react-icons/fa";
import { RiArchiveFill, RiMastercardFill } from "react-icons/ri";
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
} from "variables/general";

function Workshop() {
  // Chakra color mode
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "gray.500");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  return (
    <Flex direction="column" pt={{ base: "80px", md: "80px" }} pb={{ base: "400px", md: "400px" }}>
        <Box>
          <Grid
            templateColumns={{
              sm: "1fr",
              md: "1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            }}
            templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
            gap="26px"
          >
            <Card
              backgroundImage={BackgroundCard1}
              backgroundRepeat="no-repeat"
              background="cover"
              bgPosition="10%"
              p="16px"
              h={{ sm: "220px", xl: "100%" }}
              gridArea={{ md: "1 / 1 / 2 / 3", xl: "1 / 1 / 2 / 3" }}
            >
              <CardBody  h="100%" w="100%">
                <Flex
                  direction="column"
                  color="white"
                  h="100%"
                  p="0px 10px 20px 10px"
                  w="100%"
                >
                  <Flex justify="space-between" align="center">
                    <Text fontSize="md" fontWeight="bold">
                      Upgrade package
                    </Text>
                    <Icon
                      as={RiArchiveFill}
                      w="48px"
                      h="auto"
                      color="gray.400"
                    />
                  </Flex>
                  <Spacer />
                  <Flex direction="column">
                    <Box>
                      <Text fontSize="xl" letterSpacing="2px" fontWeight="bold">
                        Memory upgrade
                      </Text>
                    </Box>
                    <Flex mt="14px">
                      <Flex direction="column" me="34px">
                        <Text fontSize="xs">MEM</Text>
                        <Text fontSize="xs" fontWeight="bold">
                          1 Gb
                        </Text>
                      </Flex>
                      <Flex direction="column" me="34px">
                        <Text fontSize="xs">Disk</Text>
                        <Text fontSize="xs" fontWeight="bold">
                          0 Tb
                        </Text>
                      </Flex>
                      <Flex direction="column" me="34px">
                        <Text fontSize="xs">CPU</Text>
                        <Text fontSize="xs" fontWeight="bold">
                          0
                        </Text>
                      </Flex>
                      <Flex direction="column">
                        <Text fontSize="xs">Resilience</Text>
                        <Text fontSize="xs" fontWeight="bold">
                          0.3
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
            <Card
              p="16px"
              display="flex"
              align="center"
              justify="center"
              backgroundImage={plate}
              backgroundRepeat="round"

            >
              <CardBody>
                <Flex direction="column" align="center" w="100%" py="14px">
                  <IconBox as="box" h={"60px"} w={"60px"} bg={iconTeal}>
                    <Icon h={"24px"} w={"24px"} color="white" as={FaAdjust} />
                  </IconBox>
                  <Flex
                    direction="column"
                    m="14px"
                    justify="center"
                    textAlign="center"
                    align="center"
                    w="100%"
                  >
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                      Create package
                    </Text>
                    <Text
                      mb="24px"
                      fontSize="xs"
                      color="gray.400"
                      fontWeight="semibold"
                    >
                      Available ENGY
                    </Text>
                    <Separator />
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="bold">
                    500
                  </Text>
                </Flex>
              </CardBody>
            </Card>
            <Card
              p="16px"
              display="flex"
              align="center"
              justify="center"
              backgroundImage={plate}
              backgroundRepeat="round"


            >
              <CardBody>
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  w="100%"
                  py="14px"
                >
                  <IconBox as="box" h={"60px"} w={"60px"} bg={iconTeal}>
                    <Icon h={"24px"} w={"24px"} color="white" as={FaPowerOff} />
                  </IconBox>
                  <Flex
                    direction="column"
                    m="14px"
                    justify="center"
                    textAlign="center"
                    align="center"
                    w="100%"
                  >
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                      Install package
                    </Text>
                    <Text
                      mb="24px"
                      fontSize="xs"
                      color="gray.400"
                      fontWeight="semibold"
                    >
                      Available packages
                    </Text>
                    <Separator />
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="bold">
                  2
                  </Text>
                </Flex>
              </CardBody>
            </Card>
          </Grid>
          <Card p="16px" mt="24px"
                     backgroundImage={working}   
                        >
            <CardHeader>
              <Flex justify="space-between" align="center" minHeight="60px" w="100%">
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Available packages
                </Text>
                <Button
                  bg={bgButton}
                  color="white"
                  fontSize="xs"
                  variant="no-hover"
                >
                  SELECT PACKAGE
                </Button>
              </Flex>
            </CardHeader>
            <CardBody>
              <Flex
                direction={{ sm: "column", md: "column" }}
                align="center"
                w="80%"
                justifyContent="center"
                justifyItems="stretch"
                align="center"
                                py="1rem"
              >
              <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px">
              <Flex
                  p="1rem"
                  bg="transparent"
                  borderRadius="15px"
                  width="100%"
                  border="1px solid"
                  borderColor={borderColor}
                  align="center"
                  mb={{ sm: "24px", md: "0px" }}
                  me={{ sm: "0px", md: "24px" }}
                >
                  <IconBox me="10px" w="25px" h="22px">
                    <HelpIcon w="100%" h="100%" />
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    Disk upgrade 10 Tb
                  </Text>
                  <Spacer />
                  <Button
                    p="0px"
                    bg="transparent"
                    w="16px"
                    h="16px"
                    variant="no-hover"
                  >
                    <Icon as={FaPencilAlt} />
                  </Button>
                </Flex>
                <Flex
                  p="1rem"
                  bg="transparent"
                  borderRadius="15px"
                  width="100%"
                  border="1px solid"
                  borderColor={borderColor}
                  align="center"
                  mb={{ sm: "24px", md: "0px" }}
                  me={{ sm: "0px", md: "24px" }}
                >
                  <IconBox me="10px" w="25px" h="22px">
                    <HelpIcon w="100%" h="100%" />
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    Disk upgrade 15 Tb
                  </Text>
                  <Spacer />
                  <Button
                    p="0px"
                    bg="transparent"
                    w="16px"
                    h="16px"
                    variant="no-hover"
                  >
                    <Icon as={FaPencilAlt} />
                  </Button>
                </Flex>
              <Flex
                  p="1rem"
                  bg="transparent"
                  borderRadius="15px"
                  width="100%"
                  border="1px solid"
                  borderColor={borderColor}
                  align="center"
                  mb={{ sm: "24px", md: "0px" }}
                  me={{ sm: "0px", md: "24px" }}
                >
                  <IconBox me="10px" w="25px" h="22px">
                    <HelpIcon w="100%" h="100%" />
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    Disk upgrade 5 Tb
                  </Text>
                  <Spacer />
                  <Button
                    p="0px"
                    bg="transparent"
                    w="16px"
                    h="16px"
                    variant="no-hover"
                  >
                    <Icon as={FaPencilAlt} />
                  </Button>
                </Flex>
                <Flex
                  p="1rem"
                  bg="transparent"
                  borderRadius="15px"
                  width="100%"
                  border="1px solid"
                  borderColor={borderColor}
                  align="center"
                  mb={{ sm: "24px", md: "0px" }}
                  me={{ sm: "0px", md: "24px" }}
                >
                  <IconBox me="10px" w="25px" h="22px">
                    <HelpIcon w="100%" h="100%" />
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    Disk upgrade 1 Tb
                  </Text>
                  <Spacer />
                  <Button
                    p="0px"
                    bg="transparent"
                    w="16px"
                    h="16px"
                    variant="no-hover"
                  >
                    <Icon as={FaPencilAlt} />
                  </Button>
                </Flex>
                <Flex
                  p="16px"
                  bg="transparent"
                  borderRadius="15px"
                  width="100%"
                  border="1px solid"
                  borderColor={borderColor}
                  align="center"
                >
                  <IconBox me="10px" w="25px" h="25px">
                    <HelpIcon w="100%" h="100%" />
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    Memory upgrade 2 Gb
                  </Text>
                  <Spacer />
                  <Button
                    p="0px"
                    bg="transparent"
                    w="16px"
                    h="16px"
                    variant="no-hover"
                  >
                    <Icon as={FaPencilAlt} />
                  </Button>
                </Flex>
                <Flex
                  p="16px"
                  bg="transparent"
                  borderRadius="15px"
                  width="100%"
                  border="1px solid"
                  borderColor={borderColor}
                  align="center"
                >
                  <IconBox me="10px" w="25px" h="25px">
                    <HelpIcon w="100%" h="100%" />
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    Memory upgrade 4 Gb
                  </Text>
                  <Spacer />
                  <Button
                    p="0px"
                    bg="transparent"
                    w="16px"
                    h="16px"
                    variant="no-hover"
                  >
                    <Icon as={FaPencilAlt} />
                  </Button>
                </Flex>
                <Flex
                  p="16px"
                  bg="transparent"
                  borderRadius="15px"
                  width="100%"
                  border="1px solid"
                  borderColor={borderColor}
                  align="center"
                >
                  <IconBox me="10px" w="25px" h="25px">
                    <HelpIcon w="100%" h="100%" />
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    CPU upgrade 1 core
                  </Text>
                  <Spacer />
                  <Button
                    p="0px"
                    bg="transparent"
                    w="16px"
                    h="16px"
                    variant="no-hover"
                  >
                    <Icon as={FaPencilAlt} />
                  </Button>
                </Flex>
                <Flex
                  p="16px"
                  bg="transparent"
                  borderRadius="15px"
                  width="100%"
                  border="1px solid"
                  borderColor={borderColor}
                  align="center"
                >
                  <IconBox me="10px" w="25px" h="25px">
                    <HelpIcon w="100%" h="100%" />
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    CPU upgrade 2 core
                  </Text>
                  <Spacer />
                  <Button
                    p="0px"
                    bg="transparent"
                    w="16px"
                    h="16px"
                    variant="no-hover"
                  >
                    <Icon as={FaPencilAlt} />
                  </Button>
                </Flex>
              </SimpleGrid>  
              </Flex>
            </CardBody>
          </Card>
        </Box>
    </Flex>
  );
}

export default Workshop;
