const PanelContainer = {
  baseStyle: {
    p: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },
};

const PanelContainer1 = {
  baseStyle: {
    p: "2px 2px",
    minHeight: "100vh",
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer1,
  },
};
