import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FaClock, FaGift, FaLock, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
//assets
import card from "assets/img/BackgroundCard1.png";
import protocol from "assets/img/protocol.png";


function MiningProtocol(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("#F8F9FA", "gray.800");
  const nameColor = useColorModeValue("gray.500", "white");
  const { name, company, email, number, rate, rewards, speed } = props;

  return (
    <Box p="24px" bg={bgColor} my="22px" borderRadius="12px" width="65%"  
    backgroundImage={protocol}
    >
      <Flex justify="space-between" w="100%">
        <Flex direction="column" maxWidth="90%">
          <Text color={nameColor} fontSize="md" fontWeight="bold" mb="10px">
            {name}
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            Features:{" "}
            <Text as="span" color="gray.500">
              {company}
            </Text>
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            Requirements:{" "}
            <Text as="span" color="gray.500">
              {email}
            </Text>
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            VLD Supply:{" "}
            <Text as="span" color="gray.500">
              {number}
            </Text>
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            Signing rate:{" "}
            <Text as="span" color="gray.500">
              {rate}
            </Text>
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            Rewards:{" "}
            <Text as="span" color="gray.500">
              {rewards}
            </Text>
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            Speed:{" "}
            <Text as="span" color="gray.500">
              {speed}
            </Text>
          </Text>
        </Flex>
        <Flex
          direction={{ sm: "column", md: "column" }}
          align="flex-start"
          p={{ md: "24px" }}
        >
          <Button
            p="0px"
            bg="transparent"
            mb={{ sm: "10px", md: "0px" }}
            me={{ md: "12px" }}
          >
            <Flex color="red.500" cursor="pointer" align="center" p="12px">
              <Icon as={FaTrashAlt} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                Exit
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent">
            <Flex color={textColor} cursor="pointer" align="center" p="12px">
              <Icon as={FaPencilAlt} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                Join
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent">
            <Flex color={textColor} cursor="pointer" align="center" p="12px">
              <Icon as={FaGift} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                Claim
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent">
            <Flex color={textColor} cursor="pointer" align="center" p="12px">
              <Icon as={FaClock} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                Speed up
              </Text>
            </Flex>
          </Button>
          <Button p="0px" bg="transparent">
            <Flex color={textColor} cursor="pointer" align="center" p="12px">
              <Icon as={FaLock} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                Lock VLD
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}

export default MiningProtocol;
