import { QuestionIcon } from "@chakra-ui/icons";
import { Button, Flex, Link, Text, Box } from "@chakra-ui/react";
import SidebarHelpImage from "assets/img/SidebarHelpImage.png";
import IconBox from "components/Icons/IconBox";
import React from "react";
import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { useCoingeckoPrice, useCoingeckoTokenPrice } from '@usedapp/coingecko'


export function SidebarHelp(props) {
  // Pass the computed styles into the `__css` prop
  const { children, ...rest } = props;
  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);
  const STAKING_CONTRACT = '0x00000000219ab540356cBB839Cbe05303d7705Fa';
  const stakingBalance = useEtherBalance(STAKING_CONTRACT);
  const etherPrice = useCoingeckoPrice('ethereum', 'usd')


  return (
    <Flex
      borderRadius="15px"
      flexDirection="column"
      bgColor={"GrayText"}
      justifyContent="flex-start"
      alignItems="start"
      boxSize="border-box"
      p="16px"
      h="100px"
      w="100%"
    >
       {!account && <button onClick={activateBrowserWallet}> Connect wallet</button>}
      {account && <p>Account: {
                  `${account.slice(0, 6)}...${account.slice(
                    account.length - 4,
                    account.length
                  )}`}</p>}

      <Box px="3">
        <Text color="black" fontWeight="Bold" fontSize="md">
          {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ONE
        </Text>
        <Text>{etherPrice} USD</Text>
      </Box>

    </Flex>
  );
}
