// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  SimpleGrid,
  Stat,
  StatLabel,
  StatHelpText,
  StatsIcon,
  StatNumber,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import cluster from "assets/img/cluster.jpg";
import plate from "assets/img/plate-dark.jpg";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import { CreditIcon } from "components/Icons/Icons";
import { ProfileIcon } from "components/Icons/Icons";
import { MastercardIcon, VisaIcon } from "components/Icons/Icons";
import MiningProtocol from "components/Tables/MiningProtocol";
import InvoicesRow from "components/Tables/InvoicesRow";
import TransactionRow from "components/Tables/TransactionRow";
import { Separator } from "components/Separator/Separator";
import React from "react";
import {
  FaPaypal,
  FaPencilAlt,
  FaRegCalendarAlt,
  FaWallet,
} from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
} from "variables/general";

function Cluster() {
  // Chakra color mode
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "gray.500");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const iconBoxInside = useColorModeValue("white", "white");


  return (
    <Flex direction="column" pt={{ base: "80px", md: "80px" }} pb={{ base: "400px", md: "400px" }}>
     

        <Card my={{ lg: "24px" }} me={{ lg: "24px" }}
                      backgroundImage={cluster}
                      bgRepeat="cover"
                      bgPosition="center"
                      bgSize="auto"
                      >
          <Flex direction="column">
            <CardHeader py="12px">
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                Blockchain protocols
              </Text>
            </CardHeader>
            <CardBody>
              <Grid templateColumns='repeat(3, 1fr)' >
                {billingData.map((row) => {
                  return (
                    <MiningProtocol
                      name={row.name}
                      company={row.company}
                      email={row.email}
                      number={row.number}
                      rate={row.rate}
                      rewards={row.rewards}
                      speed={row.speed}

                    />
                  );
                })}
              </Grid>
            </CardBody>
          </Flex>
        </Card>
    </Flex>
  );
}

export default Cluster;
